@if (loading$ | async) {
	<app-empty-table type="campaign-loading"></app-empty-table>
} @else {
	@if (hasSubscriptions && !hasSenders) {
		<app-empty-table (senderRequest)="senderRequest.emit()"
						 [imageHeight]="236"
						 type="cw-senders">
			<span [innerHTML]="'campaigns.noSenderIdsFound' | translate"></span>
		</app-empty-table>
	}
	@if (!hasSubscriptions && hasSenders) {
		<app-empty-table (financeTransition)="financeTransition.emit()"
						 [imageHeight]="236"
						 type="cw-plans-packages">
			<span [innerHTML]="'campaigns.noPlansAndPacksNote' | translate"></span>
		</app-empty-table>
	}
	@if (!hasSubscriptions && !hasSenders) {
		<app-empty-table (senderRequest)="senderRequest.emit()"
						 (financeTransition)="financeTransition.emit()"
						 [imageHeight]="236"
						 type="cw-senders-plans-packages">
			<div class="container">
				<div>
					<div class="round">1</div>
					{{ 'campaigns.toCreateCampaign' | translate }}
				</div>
				<div>
					<div class="round">2</div>
					{{ 'campaigns.goToThe' | translate }}
					<alaris-link (click)="toPurchase()"
								 [underline]="false"
								 type="accent"
								 data-testid="goToFinance">
						{{ 'campaigns.financeSection' | translate }}
					</alaris-link>
					{{ 'campaigns.selectPlanOrPack' | translate }}
				</div>
			</div>
		</app-empty-table>
	}

}

