import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {
	AlarisButtonComponent,
	AlarisCardComponent,
	AlarisComplexTableComponent,
	AlarisEnumEntry,
	AlarisIconDirective,
	AlarisLinkComponent,
	AlarisLoadingDirective,
	AlarisLoadingStubDirective,
	AlarisOverflowFaderDirective,
	AlarisPaginationComponent,
	AlarisPBACDirective,
	AlarisProfileService,
	AlarisSettingsManagerComponent,
	AlarisTableComponent,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	EnumsMapperService,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	SharedTemplatesService,
	TABLE_STATE_SERVICE_INJECTOR,
	TableFiltersIndicator
} from '@campaign-portal/components-library';

import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CampaignAction, CampaignsService } from '../services/campaigns.service';
import { CampaignFieldsService } from './campaign-fields.service';
import { CampaignsTemplatesComponent } from './campaigns-templates/campaigns-templates.component';
import { CampaignFromFileComponent } from '../campaign-from-file/campaign-from-file.component';
import { MessageTemplatesService } from '../services/message-templates.service';
import { CampaignWizardService } from '../campaign-wizard/campaign-wizard.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { TableCellInput } from '@campaign-portal/components-library/lib/table/src/base';
import { exist } from '@campaign-portal/namespace/common/id';

@Component({
	selector: 'app-campaign-list',
	templateUrl: './campaign-list.component.html',
	styleUrls: [
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./campaign-list.component.scss'
	],
	providers: [
		CampaignFieldsService,
		AlarisTableStateService,
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService }
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisCardComponent,
		TranslateModule,
		AlarisButtonComponent,
		AlarisIconDirective,
		AlarisLinkComponent,
		AlarisPBACDirective,
		AlarisTableComponent,
		AlarisLoadingDirective,
		AlarisOverflowFaderDirective,
		AlarisPaginationComponent,
		AlarisLoadingStubDirective
	],
	standalone: true
})
export class CampaignListComponent extends AlarisComplexTableComponent<Campaign<exist>> implements OnDestroy {
	readonly CP_PERMISSIONS = CP_PERMISSIONS;

	override dropDownRowActions: RowActionSimple<Campaign<exist>>[] = [
		{
			icon: '',
			label: 'actions.details',
			action: (campaign): void => this.campaignsService.openDetailsPanel(campaign as Campaign)
		},
		{
			icon: 'icon-play',
			label: 'actions.start',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.update(
					{
						...campaign,
						info: {
							...campaign.info,
							scheduled: new Date().toISOString()
						}
					}
				)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			shown: (campaign): boolean => campaign?.info?.status === CampaignStatus.SCHEDULED
		},
		{
			icon: 'icon-play',
			label: 'actions.resume',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.action(CampaignAction.RESUME, campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			shown: (campaign): boolean => campaign?.info?.status === CampaignStatus.PAUSED
		},
		{
			icon: 'icon-pause-circle',
			label: 'actions.pause',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.action(CampaignAction.PAUSE, campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			disabled: (campaign): boolean => campaign?.info.status !== CampaignStatus.IN_PROGRESS
		},
		{
			icon: 'deprecated/stop',
			label: 'actions.cancel',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.action(CampaignAction.CANCEL, campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			disabled: (campaign): boolean =>
				campaign?.info.status === CampaignStatus.IN_PROGRESS ||
				campaign?.info.status === CampaignStatus.COMPLETED ||
				campaign?.info.status === CampaignStatus.FAILED ||
				campaign?.info.status === CampaignStatus.CANCELLED
		},
		{
			icon: 'icon-arrow-move-list',
			label: 'actions.repeat',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.cw.repeat(campaign);
			},
			disabled: (campaign): boolean =>
				campaign?.info.status === CampaignStatus.PAUSED ||
				campaign?.info.status === CampaignStatus.IN_PROGRESS ||
				campaign?.info.status === CampaignStatus.SCHEDULED,
			shown: (campaign): boolean => !Object.hasOwnProperty.call(campaign?.setup, 'file')
		},
		{
			icon: '',
			label: 'actions.delete',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.openDialog('Delete', campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			disabled: (campaign): boolean => campaign?.info.status === CampaignStatus.IN_PROGRESS,
			highlight: true,
			separator: true
		}
	];
	override ctxActions: RowActionSimple<Campaign<exist>>[] = this.dropDownRowActions;

	override readonly id = 'campaign-list';

	constructor(
		public campaignsService: CampaignsService,
		public campaignFieldsService: CampaignFieldsService, // public fieldsService,
		public override tableSettingsService: AlarisTableSettingsService, // public tableSettingsService,
		public sharedTemplates: SharedTemplatesService<TableCellInput<Campaign<exist>>>,
		private viewRef: ViewContainerRef,
		private router: Router,
		private route: ActivatedRoute,
		private templatesService: MessageTemplatesService,
		private enums: EnumsMapperService,
		private cw: CampaignWizardService,
		@Inject(PROFILE_SERVICE_INJECTOR) public profileService: AlarisProfileService
	) {
		super(
			campaignsService,
			campaignFieldsService,
			tableSettingsService,
			TableTypes.CAMPAIGN_LIST,
			DEFAULT_BUTTONS
		);
		// add templates
		this.viewRef.createComponent(CampaignsTemplatesComponent);

		if ( !this.profileService.allowed([CP_PERMISSIONS.CAMPAIGNS_E]) ) {
			this.removeActions();
		}
	}

	removeActions(): void {
		this.dropDownRowActions = this.ctxActions = [
			{
				icon: '',
				label: 'actions.details',
				action: (campaign): void => this.campaignsService.openDetailsPanel(campaign as Campaign)
			}
		];
	}

	override dblClickRowAction(campaign?: Campaign): void {
		this.campaignsService.openDetailsPanel(campaign as Campaign);
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			template: this.templatesService,
			status: { list$: new BehaviorSubject<AlarisEnumEntry[]>(this.enums.transform(CampaignStatus)) }
		});
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);
		this.state.readParams.Filters?.forEach(f => f.Field === 'status' ?
			f.Value = this.enums.fromLikeFilter(f.Value as AlarisEnumEntry[]) : f
		);
	}

	//overwrite
	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	override ngOnInit(): void {
		this.state.sorting()
			.set('lastUpdated', { enabled: true, value: SortDirection.DESC })
			.set('template', { enabled: false, value: SortDirection.EMPTY })
			.set('recipients', { enabled: false, value: SortDirection.EMPTY })
			.set('shortUrl', { enabled: false, value: SortDirection.EMPTY });

		this.state.filters()
			.set('progress', { enabled: false })
			.set('recipients', { enabled: false })
			.set('shortUrl', { enabled: false });


		super.ngOnInit();
		if ( this.route.snapshot.queryParamMap.get('mode') === 'fromFile' ) {
			this.import();
		}
		this.campaignsService.forceLoad$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.refresh();
			});

		interval(2.5 * 60000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.refresh(false);
		});
	}

	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	override ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.templates = this.sharedTemplates.templates;
		this.cd.markForCheck();
	}

	override load(showLoading = true): void {
		this.saveState();
		if ( showLoading ) {
			this.loading$.next(true);
		}
		this.campaignsService.read(this.state.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.tRows = resp.Data;
				this.total = resp.Total;
				if ( showLoading ) {
					this.loading$.next(false);
				}
				this.showLoadingStub = false;
				this.cd.markForCheck();
			});
		this.cd.markForCheck();
	}

	override refresh(showLoading = true): void {
		this.load(showLoading);
	}

	override editTableSettings(): void {
		this.editPanel.open(AlarisSettingsManagerComponent, EditPanelWidth.MD, {
			tHeads: this.tHeaders,
			tSettings: this.tSettings,
			creationFieldAvailable: false,

			tSettingsChange: this.tSettingsChange,
			headEvent: this.tHeadEvent
		});
	}

	override import(): void {
		this.editPanel.open(CampaignFromFileComponent, EditPanelWidth.MD, {});
	}

	override create(): void {
		this.router.navigate(['campaigns', 'campaign-wizard']);
	}


	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.completeRefBooksInFields(['status']);
	}
}
