<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [loading$]="loading$"
			   closeButtonText="actions.cancel"
			   confirmButtonText="actions.confirm">
	<ng-container slot="header">
		<h3>
			@switch (data.type) {
				@case ('Delete') {
					{{ "campaigns.delete" | translate }}
				}
				@case ('Captcha') {
					{{ "campaigns.readyToSent" | translate }}
				}
			}
		</h3>
	</ng-container>
	<ng-container slot="content">
		<div class="note">
			@switch (data.type) {
				@case ('Delete') {
					@if (campaign.info.status === campaignStatus.SCHEDULED) {
						<p>
							{{ 'campaigns.deleteScheduledNote' | translate }}
							{{ campaign.info.scheduled | date: 'd MMM y, HH:mm' }}
						</p>
					}
					<p>{{ 'campaigns.deleteNote' | translate }}</p>
				}
				@case ('Captcha') {
					<div class="about">
						<div class="dot"></div>
						{{ 'campaigns.name' | translate }}:
						<span class="bold"> {{ campaign.info.name | noValue }}</span>
					</div>
					<div class="about">
						<div class="dot"></div>
						{{ 'campaigns.recipients' | translate }}:
						<span class="bold"> {{ total }}</span>
					</div>
					<div class="about">
						<div class="dot"></div>
						{{ 'campaigns.scheduledDate' | translate }}:
						<span class="bold">
							{{ (campaign.info.scheduled | date: 'd MMM y, HH:mm') || ('gl.now' | translate) }}
						</span>
					</div>

					<alaris-text-area [formControl]="description"
									  class="description"
									  [errors]="errors"
									  data-testid="descriptionTextarea">
						{{ 'campaigns.noteForYourself' | translate }}
					</alaris-text-area>

					<div class="captcha">
						{{ 'gl.completeCaptcha' | translate }}
						<alaris-captcha (key)="captcha.controls.key.setValue($event)"
										[forceUpdate$]="updateCaptcha$"
										errorMode="touched"
										[formControl]="captcha.controls.answer"
										data-testid="captcha">
						</alaris-captcha>
					</div>
				}
			}
		</div>
	</ng-container>
</alaris-dialog>
