import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { LinkDomain } from '@campaign-portal/namespace/entities/link-domains/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { CampaignShortLink } from '@campaign-portal/namespace/entities/campaigns/specs';

export const URL_PATTERN = /https?:\/\/[a-zA-Z0-9$\-_.+!*'/&?=:%]+/g;

export const SHORT_LINK_PATTERN = (host?: string): RegExp => {
	const hostPattern = host
		? host
		: `(?<domain>[^\\]]*)`;

	return new RegExp(`\\[${hostPattern}\\]\\(https?://[a-z0-9$\\-_.+!*'/&?=:%]+\\)`, 'gi');
};

export const LINK_DOMAIN_TEMPLATE = (host: string): string => {
	return `https://${host}/xxxxxx`;
};

class HTMLtoChannelMessageSerializer {
	private message = '';

	constructor(message: string) {
		this.message = message;
	}

	get value(): string {
		return this.message;
	}

	removeUnbreakableSpaces(): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/&nbsp;/g, ' ')
			.replace(/\u00A0/g, ' ')
			.replace(/ /g, ' ');
		return this;
	}

	// Todo: do correct
	removeLineSpaces(): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/<div>/g, '')
			.replace(/<\/div>/g, '');
		return this;
	}

	removeVariableStyling(): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/<span class="variable" contenteditable="false">/g, '%')
			.replace(/<span class="delete"><\/span><\/span>/g, '%');
		return this;
	}

	removeVariables(variableList: string[]): HTMLtoChannelMessageSerializer {
		variableList.forEach(variable => {
			this.message = this.message.replace(
				new RegExp(`<span class="variable" contenteditable="false">${variable}</span>`, 'g'), ''
			);
		});
		return this;
	}

	changeFontDecorationTag(replaceSymbol?: string): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/<b[^>]*>/g, replaceSymbol || '*')
			.replace(/<i[^>]*>/g, replaceSymbol || '_')
			.replace(/<strike[^>]*>/g, replaceSymbol || '~')
			.replace(/<\/b>/g, replaceSymbol || '*')
			.replace(/<\/i>/g, replaceSymbol || '_')
			.replace(/<\/strike>/g, replaceSymbol || '~');
		return this;
	}
}

export class ChannelMessageToHTMLtoSerializer {
	private message = '';

	constructor(message: string) {
		this.message = message;
	}

	get value(): string {
		return this.message;
	}

	addVariableStyling(variableList: string[]): ChannelMessageToHTMLtoSerializer {
		variableList.forEach(variable => {
			this.message = this.message.replace(
				new RegExp(`%${variable}%`, 'g'),
				`<span class="variable" contenteditable="false">${variable}</span></span>`);
		});
		return this;
	}

	addUrlStyling(): ChannelMessageToHTMLtoSerializer {
		this.message = this.message.replace(
			URL_PATTERN,
			`<a target="_blank" href="$&" class="link">$&</a>`
		);
		return this;
	}

	addLinkDomains(domains: LinkDomain<exist>[], shortLink?: CampaignShortLink): ChannelMessageToHTMLtoSerializer {
		if ( !domains.length ) {
			this.message = shortLink?.shortUrl
				? this.message.replace(SHORT_LINK_PATTERN(), shortLink.shortUrl)
				: this.message;
			return this;
		}

		domains.forEach(({ id, host }) => {
			const templateLink = shortLink && shortLink.domainId === id
				? shortLink.shortUrl
				: LINK_DOMAIN_TEMPLATE(host);

			this.message = this.message.replace(
				SHORT_LINK_PATTERN(host),
				templateLink
			);
		});
		return this;
	}

	addUnbreakableSpaces(): ChannelMessageToHTMLtoSerializer {
		this.message = this.message
			.replace(/ {2}/g, '&nbsp; ');
		return this;
	}

	changeFontDecorationTag(viberSymbol: string, htmlTag: string): ChannelMessageToHTMLtoSerializer {
		const regex = new RegExp(`${viberSymbol}(.*?)${viberSymbol}`, 'g');
		this.message = this.message.replace(regex, `<${htmlTag}>$1</${htmlTag}>`);
		return this;
	}
}

export function convertToChannelMessageFormat(message: string, channel: TrafficType): string {
	const channelTemplateMessage = new HTMLtoChannelMessageSerializer(message)
		.removeVariableStyling()
		.removeUnbreakableSpaces()
		.removeLineSpaces();

	if ( channel !== TrafficType.SMS ) {
		channelTemplateMessage
			.changeFontDecorationTag();
	}

	return channelTemplateMessage.value;
}

export function convertToHTMLFormat(
	message: string, channel: TrafficType, variableList: string[],
	domains: LinkDomain<exist>[] = [], shortLink?: CampaignShortLink
): string {
	const channelTemplateMessage = new ChannelMessageToHTMLtoSerializer(message)
		.addVariableStyling(variableList)
		.addLinkDomains(domains, shortLink)
		.addUrlStyling()
		.addUnbreakableSpaces();

	if ( channel !== TrafficType.SMS ) {
		channelTemplateMessage
			.changeFontDecorationTag('\\*', 'b')
			.changeFontDecorationTag('_', 'i')
			.changeFontDecorationTag('~', 'strike');
	}
	return channelTemplateMessage.value;
}

export function convertToMessageCounterFormat(message: string, channel: TrafficType, variableList: string[]): string {
	const channelTemplateMessage = new HTMLtoChannelMessageSerializer(message)
		.removeVariables(variableList)
		.removeUnbreakableSpaces()
		.removeLineSpaces();

	if ( channel !== TrafficType.SMS ) {
		channelTemplateMessage
			.changeFontDecorationTag('');
	}

	return channelTemplateMessage.value;
}
