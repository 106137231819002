import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
	AlarisBalanceService,
	AlarisButtonComponent,
	AlarisConfigService,
	AlarisDropdownActionsComponent,
	AlarisDropdownComponent,
	AlarisDropdownDirective,
	AlarisEditPanelService,
	AlarisIconDirective,
	AlarisLinkComponent,
	AlarisPBACDirective,
	AlarisProfileService,
	AlarisProgressLineComponent,
	AlarisTabsButtonComponent,
	AlarisTagComponent,
	AlarisTooltipDirective,
	ChannelUtilsService,
	EditPanelInputData,
	formatBytes,
	RowActionSimple
} from '@campaign-portal/components-library';
import { TabButtonEntity } from '@campaign-portal/components-library/lib/tabs-button/src/base';

import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { TrafficType } from '@campaign-portal/namespace/common/enums';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { calculateBarChartMax, generateAxisYLabels } from '@helpers/utils/math';
import { CampaignAction, CampaignsService } from '../../services/campaigns.service';
import { CampaignUtilsService } from '../../services/campaign-utils.service';
import { CampaignWizardService, CONTACTS_SPLIT_PATTERN } from '../../campaign-wizard/campaign-wizard.service';
import { AvatarComponent } from '../campaigns-templates/campaign/avatar/avatar.component';
import { CurrencyPipe, DatePipe, DecimalPipe, NgTemplateOutlet, PercentPipe } from '@angular/common';
import { ContactGroupPipe } from '@helpers/repo/repo.pipe';
import { TuiAxes, TuiBarChart } from '@taiga-ui/addon-charts';
import { PhoneMessageComponent } from '../../shared/phone-message/phone-message.component';
import { UrlShortenerUtilsService } from '../../../url-shortener/url-shortener-utils.service';

@Component({
	selector: 'app-campaign-details',
	templateUrl: './campaign-details.component.html',
	styleUrls: ['./campaign-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AvatarComponent,
		TranslateModule,
		AlarisPBACDirective,
		AlarisButtonComponent,
		AlarisIconDirective,
		AlarisDropdownComponent,
		AlarisDropdownActionsComponent,
		NgTemplateOutlet,
		AlarisTagComponent,
		AlarisProgressLineComponent,
		CurrencyPipe,
		ContactGroupPipe,
		AlarisDropdownDirective,
		DatePipe,
		AlarisTooltipDirective,
		TuiAxes,
		TuiBarChart,
		AlarisTabsButtonComponent,
		PhoneMessageComponent,
		DecimalPipe,
		AlarisLinkComponent,
		PercentPipe
	],
	standalone: true
})
export class CampaignDetailsComponent implements OnDestroy {
	campaign: Campaign<exist>;
	readonly TrafficType = TrafficType;
	readonly CampaignStatus = CampaignStatus;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	readonly CONTACTS_SPLIT_PATTERN = CONTACTS_SPLIT_PATTERN;
	isOpened = false;
	isSeenExist = false;
	readonly hasShortUrlSection: boolean = false;
	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 15,
			offsetX: 0
		}
	];
	// contacts
	plainContactsOpened = false;

	// chart
	value: number[][] = [];
	readonly stubValue = [[10000, 15000, 20000]];
	barChartMax = 0;
	axisYLabels: string[] = [];

	// message template
	tabs: TabButtonEntity<TrafficType>[];
	readonly activeValue = new FormControl<TrafficType>(TrafficType.SMS, { nonNullable: true });
	protected readonly formatBytes = formatBytes;
	private readonly ngUnsubscribe = new Subject<void>();
	readonly actions: RowActionSimple<Campaign<exist>>[] = [
		{
			icon: 'icon-play',
			label: 'actions.start',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.update(
					{
						...campaign,
						info: {
							...campaign.info,
							scheduled: new Date().toISOString()
						}
					}
				)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(() => this.editPanel.close());
			},
			shown: (campaign): boolean => campaign?.info?.status === CampaignStatus.SCHEDULED
		},
		{
			icon: 'icon-play',
			label: 'actions.resume',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.action(CampaignAction.RESUME, campaign)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(() => this.editPanel.close());
			},
			shown: (campaign): boolean => campaign?.info?.status === CampaignStatus.PAUSED
		},
		{
			icon: 'icon-pause-circle',
			label: 'actions.pause',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.action(CampaignAction.PAUSE, campaign)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(() => this.editPanel.close());
			},
			disabled: (campaign): boolean => campaign?.info.status !== CampaignStatus.IN_PROGRESS
		},
		{
			icon: 'deprecated/stop',
			label: 'actions.cancel',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.action(CampaignAction.CANCEL, campaign)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(() => this.editPanel.close());
			},
			disabled: (campaign): boolean =>
				campaign?.info.status === CampaignStatus.IN_PROGRESS ||
				campaign?.info.status === CampaignStatus.COMPLETED ||
				campaign?.info.status === CampaignStatus.FAILED ||
				campaign?.info.status === CampaignStatus.CANCELLED
		},
		{
			icon: 'icon-arrow-move-list',
			label: 'actions.repeat',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.editPanel.close();
				this.cw.repeat(campaign);
			},
			disabled: (campaign): boolean =>
				campaign?.info.status === CampaignStatus.PAUSED ||
				campaign?.info.status === CampaignStatus.IN_PROGRESS ||
				campaign?.info.status === CampaignStatus.SCHEDULED,
			shown: (campaign): boolean => !Object.hasOwnProperty.call(campaign?.setup, 'file')
		},
		{
			icon: '',
			label: 'actions.delete',
			action: (campaign): void => {
				if ( !campaign ) {
					return;
				}
				this.campaignsService.openDialog('Delete', campaign)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(() => this.editPanel.close());
			},
			disabled: (campaign): boolean => campaign?.info.status === CampaignStatus.IN_PROGRESS,
			highlight: true,
			separator: true
		}
	];

	constructor(
		public bs: AlarisBalanceService,
		public cu: ChannelUtilsService,
		public campaignUtils: CampaignUtilsService,
		public readonly config: AlarisConfigService,
		public readonly shortenerUtils: UrlShortenerUtilsService,
		@Inject(EditPanelInputData) private inputData: EditPanelInputData,
		private profile: AlarisProfileService,
		private editPanel: AlarisEditPanelService,
		private campaignsService: CampaignsService,
		private translator: TranslateService,
		private cw: CampaignWizardService
	) {
		this.campaign = this.inputData.campaign as Campaign<exist>;
		this.value = this.generateData();
		this.barChartMax = calculateBarChartMax(this.value);
		this.axisYLabels = generateAxisYLabels(this.barChartMax, 5);

		this.tabs = ((this.campaign.setup ?? {}).channels ?? []).map(
			(settings) => {
				return {
					label: this.translator.instant(this.cu.name(settings.trafficType)),
					value: settings.trafficType
				};
			}
		);
		this.activeValue.patchValue(this.tabs[0]?.value as TrafficType ?? TrafficType.SMS);
		this.hasShortUrlSection = this._hasShortUrlSection();
	}

	get axisXLabels(): string[] {
		const isViberChannel = this.campaign.setup.channels.find(c => c.trafficType === TrafficType.VIBER);
		return isViberChannel ?
			[
				this.translator.instant('enums.SENT'),
				this.translator.instant('enums.DELIVERED'),
				this.translator.instant('enums.SEEN'),
				this.translator.instant('enums.UNDELIVERED'),
				this.translator.instant('enums.FAILED')
			] : [
				this.translator.instant('enums.SENT'),
				this.translator.instant('enums.DELIVERED'),
				this.translator.instant('enums.UNDELIVERED'),
				this.translator.instant('enums.FAILED')
			];
	}

	generateData(): number[][] {
		const isViberChannel = this.campaign.setup.channels.find(c => c.trafficType === TrafficType.VIBER);
		const dataForViber = [
			this.campaign.statistics?.sent,
			this.campaign.statistics?.delivered,
			this.campaign.statistics?.seen,
			this.campaign.statistics?.undelivered,
			this.campaign.statistics?.failed
		];
		const dataForSms = [
			this.campaign.statistics?.sent,
			this.campaign.statistics?.delivered,
			this.campaign.statistics?.undelivered,
			this.campaign.statistics?.failed
		];
		const result: number[][] = [];
		this.isSeenExist = !!isViberChannel;
		const data = isViberChannel ? dataForViber : dataForSms;
		data.forEach((v, i) => {
			const arr = new Array(data.length).fill(0);
			arr[i] = v;
			result.push(arr);
		});
		return result;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	export(id: string, fileName?: string): void {
		this.campaignsService.export(id, fileName).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
	}

	action(campaign: Campaign<exist>): void {
		switch (this.campaign.info.status) {
			case CampaignStatus.SCHEDULED:
				// start
				this.actions[0].action(campaign);
				break;
			case CampaignStatus.PAUSED:
				// resume
				this.actions[1].action(campaign);
				break;
			case CampaignStatus.IN_PROGRESS:
				// pause
				this.actions[2].action(campaign);
				break;
			case CampaignStatus.FAILED:
			case CampaignStatus.CANCELLED:
			case CampaignStatus.COMPLETED:
				if ( Object.hasOwnProperty.call(campaign.setup, 'file') ) {
					this.actions[5].action(campaign);

				} else {
					this.actions[4].action(campaign);

				}
				break;
			default:
				return;
		}
	}

	navigateToShortener(): void {
		const allowed = this.profile.allowed([CP_PERMISSIONS.URL_SHORTENER_E]);
		const shortLink = this.campaign.info.shortLink;
		if ( shortLink && allowed ) {
			this.shortenerUtils.navigateToShortener(shortLink);
			this.editPanel.close();
		}
	}

	private _hasShortUrlSection(): boolean {
		const hasShortUrl = (this.campaign.setup?.channels ?? [])
			.some(settings => {
				const message = this.campaignUtils.message(this.campaign, settings.trafficType);
				return this.shortenerUtils.extractShortLink(message);
			});

		return Boolean(
			this.campaign.info.shortLink ||
			(this.profile.allowed([CP_PERMISSIONS.URL_SHORTENER_E])
				&& this.campaign.info.status === CampaignStatus.SCHEDULED
				&& hasShortUrl)
		);
	}
}
