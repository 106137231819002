<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmButtonText]="''">
	<ng-container slot="header">
		{{ "purchase.countryList" | translate }}
	</ng-container>

	<ng-container slot="content">
		<div class="name">
			<alaris-icon [name]="cu.icon(item.trafficType)" class="channel-icon"></alaris-icon>
			{{item.name}}
		</div>
		<div class="table-content">
			<alaris-table (filtersChange)="applyFilter($event)"
						  (sortingChange)="applySorting($event)"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="headers"
						  [tRows]="tRows"
						  class="all-country-table">
			</alaris-table>
		</div>
	</ng-container>
</alaris-dialog>
