import { Directive, ElementRef, forwardRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[contenteditable][formControlName],[contenteditable][formControl],[contenteditable][ngModel]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => ContentEditableDirective),
		multi: true
	}],
	standalone: true
})
export class ContentEditableDirective implements ControlValueAccessor {
	@HostBinding('attr.contenteditable') @Input() contenteditable = true;

	private onChange!: (value: string) => void;
	private onTouched!: () => void;
	private removeDisabledState!: () => void;

	constructor(
		private readonly elementRef: ElementRef,
		private readonly renderer: Renderer2
	) {
	}

	static listenerDisabledState(e: KeyboardEvent): void {
		e.preventDefault();
	}

	private static processValue(value: unknown): string {
		const processed = String(value == null ? '' : value);
		return processed.trim() === '<br>' ? '' : processed;
	}

	@HostListener('input')
	callOnChange(): void {
		if ( typeof this.onChange === 'function' ) {
			this.onChange(ContentEditableDirective.processValue(this.elementRef.nativeElement.innerHTML));
		}
	}

	@HostListener('blur')
	callOnTouched(): void {
		if ( typeof this.onTouched === 'function' ) {
			this.onTouched();
		}
	}

	/**
	 * Writes a new value to the element.
	 * This method will be called by the forms API to write
	 * to the view when programmatic (model -> view) changes are requested.
	 *
	 * See: [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor#members)
	 */
	writeValue(value: unknown): void {
		this.renderer.setProperty(
			this.elementRef.nativeElement,
			'innerHTML',
			ContentEditableDirective.processValue(value)
		);
	}

	/**
	 * Registers a callback function that should be called when
	 * the control's value changes in the UI.
	 *
	 * This is called by the forms API on initialization so it can update
	 * the form model when values propagate from the view (view -> model).
	 */
	registerOnChange(fn: () => void): void {
		this.onChange = fn;
	}

	/**
	 * Registers a callback function that should be called when the control receives a blur event.
	 * This is called by the forms API on initialization so it can update the form model on blur.
	 */
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * This function is called by the forms API when the control status changes to or from "DISABLED".
	 * Depending on the value, it should enable or disable the appropriate DOM element.
	 */
	setDisabledState(isDisabled: boolean): void {
		if ( isDisabled ) {
			this.renderer.setAttribute(
				this.elementRef.nativeElement,
				'disabled',
				'true'
			);
			this.removeDisabledState = this.renderer.listen(
				this.elementRef.nativeElement,
				'keydown',
				ContentEditableDirective.listenerDisabledState
			);
		} else {
			if ( this.removeDisabledState ) {
				this.renderer.removeAttribute(
					this.elementRef.nativeElement,
					'disabled'
				);
				this.removeDisabledState();
			}
		}
	}
}
