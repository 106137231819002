<div [alarisLoading]="loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-import-contact"></alaris-icon>
		<span>{{ 'campaigns.campaignFromFile' | translate }}</span>
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		@if (hasSenders && hasSubscriptions) {
			<alaris-input [formControl]="cw.infoControls.name"
						  [label]="'campaigns.campaignName' | translate"
						  [placeholder]="'campaigns.campaignName' | translate" data-testid="nameInput">
			</alaris-input>

			<div class="traffic-type">
				<alaris-select2 [formControl]="channelControls.trafficType"
								[label]="'gl.deliveryChannel' | translate"
								[placeholder]="'gl.deliveryChannel' | translate"
								class="w-50"
								data-testid="trafficTypeSelect" selectedLabel="name">
					@for (opt of channelsService.list$ | async; track $index) {
						<alaris-options2 [attr.data-testid]="'trafficTypeOption/' + opt.channelType"
										 [value]="opt.channelType">
							{{ opt.name }}
						</alaris-options2>
					}
				</alaris-select2>
				<alaris-select2 [displayWith]="displaySender.bind(this)"
								[formControl]="channelControls.sender"
								[label]="'senderIds.senderId' | translate"
								[placeholder]="'senderIds.senderId' | translate"
								class="w-50"
								data-testid="senderIdsSelect">
					@if (senders.length > 5) {
						<alaris-select-filter2 [formControl]="filterSenderIdsControl" data-testid="senderIdsFilter">
						</alaris-select-filter2>
					}
					@for (opt of senders | alarisFilter : sendersMatcher : filterSenderIdsControl.value; track $index) {
						<alaris-options2 [attr.data-testid]="'senderIdsOption/' + opt.name" [value]="opt.id">
							{{ opt.name }}
						</alaris-options2>
					}
				</alaris-select2>
			</div>
			@if (channelControls.trafficType.value === TrafficType.SMS && profileService.user.details.isFlashed) {
				<alaris-checkbox [formControl]="cw.settingsControls.isFlashed" data-testid="isFlashedCheckbox">
					<div class="flash">
						{{ 'gl.smsFlash' | translate }}
						<alaris-icon [alarisTooltip]="'gl.flashNote' | translate" name="icon-info"></alaris-icon>
					</div>
				</alaris-checkbox>
				@if (cw.settingsControls.isFlashed.value) {
					<div class="flash-note">{{ 'gl.flashNoteLength' | translate }}</div>
				}
			}

			<div class="file">
				<div class="label">{{ 'campaigns.uploadFile' | translate }}</div>
				<alaris-file-loader (fileListChanges)="addFiles($event)"
									[exportInternally]="true"
									[fileTypes]="'.csv,.xls,.xlsx,.numbers'"
									[multiple]="false"
									[required]="true" data-testid="campaignFile">
					<div class="loader-hint">
						{{ 'contacts.import.fileHintPart1' | translate }}
						<alaris-link [href]="templateRef" data-testid="sampleTemplateLink" type="accent">
							{{ 'contacts.import.fileHintPart2' | translate }}
						</alaris-link>
						{{ 'contacts.import.fileHintPart3' | translate }}
					</div>
				</alaris-file-loader>
				@if (fileRows.length > 0) {
					<alaris-parsed-file-table (columns)="cw.setupControls.file.controls.columns.setValue($event)"
											  [fields]="fields"
											  [rows]="fileRows">
					</alaris-parsed-file-table>
				}
				@if (fileLoading$ | async) {
					<div [alarisLoading]="fileLoading$" style="width: 100%; height: 10rem"></div>
				}
			</div>
			@if (channelControls.trafficType.value === TrafficType.VIBER) {
				<div class="options">
					<div class="label">{{ 'campaigns.viberType' | translate }}</div>
					<div class="omni">
						<alaris-radio [alarisComplexTooltip]="tooltipPriorityComponent"
									  [attr.data-testid]="'viberType/' + MessagePurpose.TRANSACTION"
									  [componentData]="MessagePurpose.TRANSACTION"
									  [formControl]="channelControls.omni!.controls.viberSettings!.controls.messagePurpose"
									  [value]="MessagePurpose.TRANSACTION"
									  name="priority">
							{{ 'campaigns.transactional' | translate }}
						</alaris-radio>
						<alaris-radio [alarisComplexTooltip]="tooltipPriorityComponent"
									  [attr.data-testid]="'viberType/' + MessagePurpose.PROMOTION"
									  [componentData]="MessagePurpose.PROMOTION"
									  [formControl]="channelControls.omni!.controls.viberSettings!.controls.messagePurpose"
									  [value]="MessagePurpose.PROMOTION" name="priority">
							{{ 'campaigns.promotional' | translate }}
						</alaris-radio>
					</div>
				</div>
			}

			<div class="options">
				<div class="label">{{ 'campaigns.scheduleCampaign' | translate }}</div>
				<alaris-radio [formControl]="scheduledChoiceControl" data-testid="scheduledChoiceRadio_now"
							  name="scheduled" value="now">
					{{ 'campaigns.sendNow' | translate }}
				</alaris-radio>
				<alaris-radio [formControl]="scheduledChoiceControl" data-testid="scheduledChoiceRadio_future"
							  name="scheduled" value="future">
					{{ 'campaigns.schedule' | translate }}
				</alaris-radio>
			</div>
			@if (scheduledChoiceControl.value === 'future') {
				<alaris-scheduled [control]="cw.infoControls.scheduled"></alaris-scheduled>
			}
		} @else {
			<app-empty-campaign-card (financeTransition)="moveToPurchase()"
									 (senderRequest)="openSenderIdRequestPanel()"
									 [hasSenders]="hasSenders"
									 [hasSubscriptions]="hasSubscriptions"
									 [loading$]="loading$">
			</app-empty-campaign-card>
		}
		@if (approximateCampaignData) {
			<div [alarisLoading]="analysis$"
				 class="analysis">
				<h3 class="title">{{ 'campaigns.campaignDetails' | translate }}</h3>
				<div class="content">
					<div class="item">
						<div class="label"> {{ 'campaigns.approximateCost' |translate }}</div>
						<div class="strong">
							{{ (approximateCampaignData.cost.min || 0) | currency: bs.currencyCode }}
						</div>
						<span style="margin-left: 3rem;">-</span>
						<div class="strong">
							{{ (approximateCampaignData.cost.max || 0) | currency: bs.currencyCode }}
						</div>
					</div>

					<div class="item">
						<div class="label">{{ 'campaigns.totalRecipients' |translate }}</div>
						<div class="strong">{{ approximateCampaignData.recipients }}</div>
					</div>

					<div class="chart">
						<tui-ring-chart [value]="[
								approximateFileAnalysis?.correctRow || 0,
								approximateFileAnalysis?.wrongFormatRow || 0,
								approximateFileAnalysis?.duplicatesRow || 0
								]" size="s">
						</tui-ring-chart>

						<div class="legend">
							@for (label of ['correctRow', 'wrongFormatRow', 'duplicatesRow']; let index = $index; track index) {
								<tui-legend-item [color]="'var(--tui-chart-categorical-0'+index+')'"
												 [text]="('campaigns.' + label) |translate"
												 size="s">
									<strong>{{ approximateFileAnalysis ? approximateFileAnalysis[label] : 0 }}</strong>
								</tui-legend-item>
							}
						</div>
					</div>
				</div>
			</div>

		}
	</div>
	<div class="edit-panel-footer">
		<button (click)="createCampaign()"
				[disabled]="createCampaignDisabled"
				alaris-button
				bType="primary"
				data-testid="createBtn"
				size="lg" type="button">
			{{ 'campaigns.createCampaign' | translate }}
		</button>
		<button (click)="campaignDetails()"
				[disabled]="createCampaignDisabled"
				[progress]="analysis$"
				alaris-button
				bType="outline"
				data-testid="campaignDetailsBtn"
				size="lg" type="button">
			{{ 'campaigns.campaignDetails' | translate }}
		</button>
	</div>

</div>
