import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	Inject,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { convertToHTMLFormat } from '@helpers/utils/template-message.serializer';
import { ContactFieldsService } from '../../../contacts/services/contact-fields.service';
import {
	AbstractConfigService,
	AlarisFilesService,
	AlarisIconDirective,
	CONFIG_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LinkDomainsService } from '../../../url-shortener/link-domains.service';
import { CampaignShortLink } from '@campaign-portal/namespace/entities/campaigns/specs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-phone-message',
	templateUrl: './phone-message.component.html',
	styleUrls: ['./phone-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		TranslateModule,
		DatePipe,
		AlarisIconDirective
	],
	standalone: true
})
export class PhoneMessageComponent implements OnChanges, AfterViewInit {
	@ViewChild('messageTemplate') readonly messageTemplate!: ElementRef<HTMLDivElement>;

	// main message params
	@Input() trafficType!: TrafficType;
	@Input() sender = '';
	@Input() message = '';
	// omni message params
	@Input() buttonActionUrl?: string;
	@Input() imageUrl?: string;
	@Input() buttonCaption?: string;
	@Input() shortLink?: CampaignShortLink;
	// message from file params
	@Input() file?: FileInfo;

	readonly TrafficType = TrafficType;
	readonly today = new Date();

	readonly destroyRef = inject(DestroyRef);

	themeType = toSignal(this.appConfigService.themeType$);

	constructor(
		private readonly fs: AlarisFilesService,
		private readonly contactFieldsService: ContactFieldsService,
		private readonly domains: LinkDomainsService,
		@Inject(CONFIG_SERVICE_INJECTOR) private readonly appConfigService: AbstractConfigService
	) {
	}

	get style(): string {
		return `background-image: url(assets/img/phone-${this.trafficType}-${this.themeType()}.png)`;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.message ) {
			this.convert();
		}
	}

	ngAfterViewInit(): void {
		this.convert();
	}

	download(): void {
		if ( this.file?.id ) {
			this.fs.export(this.file.id, this.file.name)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe();
		}
	}

	private convert(): void {
		if ( !this.messageTemplate ) {
			return;
		}

		this.messageTemplate.nativeElement.innerHTML = convertToHTMLFormat(
			this.message,
			this.trafficType,
			this.contactFieldsService.variables$.getValue(),
			this.shortLink ? this.domains.list : this.domains.active$.value,
			this.shortLink
		);
	}
}
