import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { TableCellInput } from '@campaign-portal/components-library/lib/table/src/base';
import {
	AlarisBalanceService,
	AlarisCellContextDirective,
	AlarisComplexTooltipDirective,
	AlarisLinkComponent,
	AlarisProgressLineComponent,
	AlarisTagComponent,
	AlarisTooltipDirective,
	SharedTemplatesService
} from '@campaign-portal/components-library';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';

import { CampaignUtilsService } from '../../services/campaign-utils.service';
import { CampaignProgressTooltipComponent } from './campaign-progress-tooltip/campaign-progress-tooltip.component';
import { CampaignComponent } from './campaign/campaign.component';
import { TemplateComponent } from './template/template.component';
import { TranslateModule } from '@ngx-translate/core';
import { RecipientsComponent } from './recipients/recipients.component';
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { UrlShortenerUtilsService } from '../../../url-shortener/url-shortener-utils.service';

@Component({
	selector: 'app-campaigns-templates',
	templateUrl: './campaigns-templates.component.html',
	styleUrls: ['./campaigns-templates.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CampaignComponent,
		AlarisCellContextDirective,
		TemplateComponent,
		AlarisTagComponent,
		TranslateModule,
		AlarisProgressLineComponent,
		AlarisComplexTooltipDirective,
		RecipientsComponent,
		CurrencyPipe,
		DatePipe,
		DecimalPipe,
		AlarisLinkComponent,
		PercentPipe,
		AlarisTooltipDirective
	],
	standalone: true
})
export class CampaignsTemplatesComponent implements AfterViewInit, OnDestroy {
	public readonly campaignCtx!: Campaign;

	@ViewChild('campaign') readonly campaign!: TemplateRef<TableCellInput>;
	@ViewChild('template') readonly template!: TemplateRef<TableCellInput>;
	@ViewChild('status') readonly status!: TemplateRef<TableCellInput>;
	@ViewChild('progress') readonly progress!: TemplateRef<TableCellInput>;
	@ViewChild('shortUrl') readonly shortUrl!: TemplateRef<TableCellInput>;
	@ViewChild('recipients') readonly recipients!: TemplateRef<TableCellInput>;
	@ViewChild('cost') readonly cost!: TemplateRef<TableCellInput>;
	@ViewChild('startDate') readonly startDate!: TemplateRef<TableCellInput>;
	@ViewChild('finishedDate') readonly finishedDate!: TemplateRef<TableCellInput>;
	@ViewChild('scheduled') readonly scheduled!: TemplateRef<TableCellInput>;
	@ViewChild('lastUpdated') readonly lastUpdated!: TemplateRef<TableCellInput>;

	readonly CampaignProgressTooltipComponent = CampaignProgressTooltipComponent;

	constructor(
		public readonly bs: AlarisBalanceService,
		public readonly campaignUtils: CampaignUtilsService,
		public readonly shortenerUtils: UrlShortenerUtilsService,
		private readonly templates: SharedTemplatesService<TableCellInput>
	) {
	}

	ngAfterViewInit(): void {
		this.templates.share({
			campaign: this.campaign,
			template: this.template,
			status: this.status,
			progress: this.progress,
			shortUrl: this.shortUrl,
			recipients: this.recipients,
			cost: this.cost,
			startDate: this.startDate,
			finishedDate: this.finishedDate,
			scheduled: this.scheduled,
			lastUpdated: this.lastUpdated
		});
	}

	ngOnDestroy(): void {
		this.templates.clear();
	}

}
