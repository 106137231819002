<div [class.sms]="trafficType === TrafficType.SMS"
	 [class.viber]="trafficType !== TrafficType.SMS"
	 [style]="style"
	 class="phone">
	<div class="sender">
		{{ sender }}
	</div>
	<div class="write-message-hint">
		{{ 'templates.writeMessage' | translate }}
	</div>
	@if (file) {
		<div class="stub">
			<div class="note-section">
				<span class="title">{{ 'campaigns.sentFromFile' | translate }}</span> <br>
				<div class="note">{{ 'campaigns.sentFromFileNote' | translate }}</div>
			</div>
			<button (click)="download()" class="reset download-section" data-testid="downloadFileBtn">
				<div class="icon-round">
					<alaris-icon name="icon-import"></alaris-icon>
				</div>
				{{ 'actions.downloadFile' | translate }}
			</button>
		</div>
	} @else {
		<div class="message-box">
			<div class="today">{{ 'gl.today' | translate }}</div>
			<div class="message">
				<div #messageTemplate class="text">
					<!--				{{message}}-->
				</div>
				@if ((trafficType !== TrafficType.SMS) && (imageUrl || buttonCaption)) {
					@if (imageUrl) {
						<img [alt]="'templates.image' | translate" [src]="imageUrl">
					}
					@if (buttonCaption) {
						<a [attr.href]="buttonActionUrl" target="_blank">
							{{ buttonCaption }}
						</a>
					}
				}
			</div>
			<div class="time">{{ today | date: 'HH:mm' }}</div>
		</div>
	}
</div>
