import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject, tap } from 'rxjs';

import {
	ClientSubscriptionsReadResponse,
	Package,
	PackRecord,
	Plan,
	RequestSubscriptionRequest,
	SubscribeRequest,
	SubscriptionsSubscribedResponse,
	UnsubscribeRequest
} from '@campaign-portal/namespace/entities/subscriptions/specs';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { UpdateResponse } from '@campaign-portal/namespace/common/implementations';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import {
	AlarisApiService,
	AlarisDialogService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisProgressLineType,
	AlarisToasterService,
	ErrorNotifierConfig,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { PurchaseDialogsComponent, PurchaseDialogType, PurchaseHintDialogType } from './dialogs/dialogs.component';
import { ComponentType } from '@angular/cdk/overlay';
import { AllCountriesDialogComponent } from './dialogs/all-countries-dialog/all-countries-dialog.component';
import { catchError } from 'rxjs/operators';
import { CP_PERMISSIONS } from '@helpers/types/permissions';

type PackageStore = Map<Id<exist>, Package>;
export type PackRecordsStore = Map<Id<exist>, PackRecord<exist>[]>;

export interface SubscriptionsSubscribed {
	plans: Plan[],
	packRecords: PackRecordsStore
}

@Injectable({
	providedIn: 'root'
})
export class PurchaseService {

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly loadingRead$ = new BehaviorSubject<boolean>(false);
	readonly loadingSubscribed$ = new BehaviorSubject<boolean>(false);
	readonly update$ = new Subject();

	packRecords: PackRecordsStore = new Map();
	subscribedPacks: PackageStore = new Map();
	subscribedPlans: Plan[] = [];

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly dialog: AlarisDialogService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.purchase');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.purchase');
	}

	calcProgress(records: PackRecord[]): [used: number, total: number, locked: number] {
		return records.reduce((res, record) => {
			const pack = this.subscribedPacks.get(record.subscriptionId);
			res[0] = (record.messagesUsed ?? 0) + res[0];
			res[1] = (pack?.messagesTotal ?? 0) + res[1];
			res[2] = (record.messagesLocked ?? 0) + res[2];
			return res;
		}, [0, 0, 0]);
	}

	static packUsedState(rest: number, total: number): AlarisProgressLineType {
		const progress = (rest / total) * 100;
		return progress > 70 && progress <= 100
			? 'success'
			: progress <= 70 && progress > 50
				? 'warning'
				: progress <= 50
					? 'negative'
					: 'success';
	}

	request(params: RequestSubscriptionRequest): Observable<RPCResult<void>> {
		return this.api.loader<RPCResult<void>>(
			'Subscriptions.Request',
			params,
			this.loading$,
			this.errorNotifier,
			this.prepareNotification('request')
		);
	}

	read(params?: RPCRequestParams): Observable<ClientSubscriptionsReadResponse> {
		return this.api.loader<ClientSubscriptionsReadResponse>(
			'Subscriptions.Read', params, this.loadingRead$, this.errorNotifier
		);
	}

	subscribed(params?: RPCRequestParams): Observable<{
		Data: SubscriptionsSubscribed
	}> {
		let request: Observable<SubscriptionsSubscribedResponse> = of({
			Success: true,
			Total: 0,
			Data: { packRecords: [], packs: [], plans: [] }
		});

		if ( this.profile.allowed([CP_PERMISSIONS.SUBSCR_R]) ) {
			request = this.api.loader<SubscriptionsSubscribedResponse>(
				'Subscriptions.Subscribed', params, this.loadingSubscribed$, this.errorNotifier
			);
		}

		return request.pipe(
			map(
				(resp): { Data: SubscriptionsSubscribed } => {
					this.packRecords = this.storePackRecords(resp.Data.packRecords);
					this.subscribedPlans = resp.Data.plans;
					this.subscribedPacks = resp.Data.packs.reduce((map, pack) => {
						map.set(pack.id, pack);
						return map;
					}, new Map() as PackageStore);
					return {
						Data: {
							plans: resp.Data.plans,
							packRecords: this.packRecords
						}
					};
				}
			),
			catchError((resp) => {
				resp.Data = {
					plans: [],
					packRecords: new Map()
				};
				return of(resp);
			})
		);
	}

	subscribe(params: SubscribeRequest): Observable<UpdateResponse<Package | Plan>> {
		return this.api.loader<UpdateResponse<Package | Plan>>(
			'Subscriptions.Subscribe', params, this.loading$, this.errorNotifier, this.prepareNotification('subscribe')
		);
	}

	unsubscribe(params: UnsubscribeRequest): Observable<UpdateResponse<Package | Plan>> {
		return this.api.loader<UpdateResponse<Package | Plan>>(
			'Subscriptions.Unsubscribe', params, this.loading$,
			this.errorNotifier, this.prepareNotification('unsubscribe')
		);
	}

	openSpecificHintDialog(type: PurchaseHintDialogType, item: Plan | Package): void {
		let component: ComponentType<unknown>;
		switch (type) {
			case 'AllCountries':
				component = AllCountriesDialogComponent;
				break;
		}

		this.dialog.open(component, {
			data: { item },
			autoFocus: '__no_exist_element__'
		});
	}

	openSpecificDialog(
		type: PurchaseDialogType,
		item: { plan?: Plan; package?: Package } | null = null
	): Observable<unknown> {
		const subscrItemObj = item !== null ? { package: item.package ?? null, plan: item.plan ?? null } : {};
		return this.dialog.open(PurchaseDialogsComponent, {
			data: { type, ...subscrItemObj },
			autoFocus: false,
			panelClass: 'scroll-cdk-dialog'
		}).closed.pipe(
			tap(result => {
				if ( result ) {
					this.update$.next(result);
				}
				return result;
			}));
	}

	packActiveTillDate(pack: Package): string {
		const { creationDate, packSettings } = pack;
		if ( !creationDate || !packSettings?.validityPeriodByDay ) {
			return '';
		}
		const baseDate = new Date();
		baseDate.setDate(baseDate.getDate() + packSettings.validityPeriodByDay);
		return baseDate.toISOString();
	}

	packRecordActiveTillDate(packRecord: PackRecord<exist>): string {
		return packRecord.activeTo ?? '';
	}

	isExpireDateClose(packRecord: PackRecord<exist>): boolean {
		const activeTill = this.packRecordActiveTillDate(packRecord);
		if ( !activeTill ) {
			return false;
		}
		return Math.round((Date.parse(activeTill) - Date.now()) / (1000 * 3600 * 24)) < 30;
	}

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	private storePackRecords(records: PackRecord<exist>[]): PackRecordsStore {
		const store: PackRecordsStore = new Map();
		records.forEach((record) => {
			const expired = record.activeTo && new Date(record.activeTo) < new Date();
			if ( expired ) {
				return;
			}

			if ( store.has(record.subscriptionId) ) {
				store.get(record.subscriptionId)?.push(record);
			} else {
				store.set(record.subscriptionId, [record]);
			}
		});
		return store;
	}

	private prepareNotification(type: 'subscribe' | 'unsubscribe' | 'request'): (response: RPCResult<unknown>) => void {
		let message: string;
		switch (type) {
			case 'subscribe':
				message = this.langService.translate('notifications.actions.subscribe', { entity: this.entity });
				break;
			case 'unsubscribe':
				message = this.langService.translate('notifications.actions.unsubscribe', { entity: this.entity });
				break;
			case 'request':
				message = this.langService.translate('notifications.actions.request', { entity: this.entity });
				break;
			default:
				message = this.langService.translate('enums.SUCCESS');
		}
		return (response: RPCResult<unknown>): void => {
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
	}

}
