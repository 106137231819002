import { Injectable, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';

import { AlarisProfileService, CustomValidators } from '@campaign-portal/components-library';

import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { DecisionMode, MessagePurpose, MessageSplitMode, TrafficType } from '@campaign-portal/namespace/common/enums';
import {
	Campaign,
	CampaignChannelSettings,
	CampaignStatus,
	CampaignViberSettings
} from '@campaign-portal/namespace/entities/campaigns/specs';
import { FileParsedColumn } from '@campaign-portal/namespace/entities/files/specs';

import { CPCustomValidators } from '@helpers/validators/custom-validators';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import {
	CampaignChannelSettingsControls,
	CampaignControls,
	CampaignInfoControls,
	CampaignMessageControls,
	CampaignNativeControls,
	CampaignOmniSettingsControls,
	CampaignSettingsControls,
	CampaignSetupControls,
	CampaignViberSettingsControls
} from '../shared/types/form-types';
import { ContactGroupsService } from '../../contacts/services/contact-groups.service';
import { MessageTemplatesService } from '../services/message-templates.service';
import { ShortLinkEntry, UrlShortenerUtilsService } from '../../url-shortener/url-shortener-utils.service';

export const MAX_PHONE_NUMBER_AMOUNT = 1000;
export const CONTACTS_SPLIT_PATTERN = /[\s\n;,]+\s*/gm;

export type ShortLinkChannelsMap = Map<TrafficType, ShortLinkEntry | undefined>;

@Injectable({
	providedIn: 'root'
})
export class CampaignWizardService {
	totalRecipients = signal(0);
	forbiddenFlashWarning = false;
	readonly shortLinkChannelsMap$ = new BehaviorSubject<ShortLinkChannelsMap>(new Map());

	constructor(
		private readonly contactGroupsService: ContactGroupsService,
		private readonly templatesService: MessageTemplatesService,
		private readonly shortenerUtils: UrlShortenerUtilsService,
		private readonly profile: AlarisProfileService,
		private readonly translate: TranslateService,
		private readonly router: Router,
		private readonly datePipe: DatePipe
	) {
	}

	_form!: FormGroup<CampaignControls>;

	get form(): FormGroup<CampaignControls> {
		return this._form;
	}

	set form(form: FormGroup<CampaignControls>) {
		this._form = form;
	}

	get infoControls(): CampaignInfoControls {
		return this._form.controls.info.controls;
	}

	get setupControls(): CampaignSetupControls {
		return this._form.controls.setup.controls;
	}

	get settingsControls(): CampaignSettingsControls {
		return this._form.controls.settings.controls;
	}

	get contactsControls(): FormGroup<{
		file: FormControl<FileInfo | undefined>;
		plain: FormGroup<{
			text: FormControl<string>;
			code: FormControl<number | null>;
		}>;
		groups: FormControl<Id<exist>[] | undefined>;
	}> {
		return this._form.controls.setup.controls.native.controls.contacts;
	}

	get channelsArray(): FormGroup<CampaignChannelSettingsControls>[] {
		return this._form.controls.setup.controls.channels.controls;
	}

	get contactsFile(): FormControl<FileInfo | undefined> {
		return this._form.controls.setup.controls.native.controls.contacts.controls.file;
	}

	get contactsPlain(): FormGroup<{ text: FormControl<string>; code: FormControl<number | null> }> {
		return this._form.controls.setup.controls.native.controls.contacts.controls.plain;
	}

	get contactGroups(): FormControl<Id<exist>[] | undefined> {
		return this._form.controls.setup.controls.native.controls.contacts.controls.groups;
	}

	findChannel(trafficType: TrafficType): FormGroup<CampaignChannelSettingsControls> | undefined {
		return this._form.controls.setup.controls.channels.controls.find((control) => {
			return control.controls.trafficType.value === trafficType;
		});
	}

	create(type: 'file' | 'native' = 'native', campaign?: Campaign): FormGroup<CampaignControls> {
		this._form = this.initializeForm(campaign);
		if ( type === 'file' ) {
			this.disableNativeSetup();
		} else {
			this.disableFileSetup();
		}
		return this._form;
	}

	reset(): void {
		this._form = this.create();
		this.totalRecipients.set(0);
		this.forbiddenFlashWarning = false;
		this.shortLinkChannelsMap$.next(new Map());
	}

	prepare(type: 'file' | 'native' = 'native'): Campaign<null> {
		const baseCampaign: Campaign<null> = this.initializeBaseCampaign();

		let campaign: Campaign<null>;

		if ( type === 'file' ) {
			campaign = this.prepareFileCampaign(baseCampaign);
		} else {
			campaign = this.prepareNativeCampaign(baseCampaign);
		}

		return campaign;
	}

	repeat(campaign: Campaign): void {
		if ( campaign.id ) {
			campaign.info.name = this.translate.instant('actions.repeat') + ' ' + campaign.info.name;
		}
		if ( campaign.settings.isFlashed && !this.profile.user.details.isFlashed ) {
			this.forbiddenFlashWarning = true;
			campaign.settings.isFlashed = false;
		}
		this.totalRecipients.set(campaign.statistics?.total || 0);
		this.create('native', campaign);
		this.checkShortLinks();
		this.router.navigate(['campaigns', 'campaign-wizard']);
	}

	checkShortLinks(): void {
		if ( !this.profile.allowed([CP_PERMISSIONS.URL_SHORTENER_E]) ) {
			return;
		}
		const shortLinkChannelsMap: ShortLinkChannelsMap = new Map();
		this.channelsArray.forEach(ch => {
			const channel = ch.getRawValue();
			if ( !channel.message ) {
				return;
			}
			const message = channel.message.text ??
				this.templatesService.map.get(channel.message.templateID)?.messageTemplate ?? '';
			shortLinkChannelsMap.set(channel.trafficType, this.shortenerUtils.extractShortLink(message));
		});

		this.shortLinkChannelsMap$.next(shortLinkChannelsMap);
	}

	private initializeForm(campaign?: Campaign): FormGroup<CampaignControls> {
		return new FormGroup<CampaignControls>({
			id: new FormControl<Id>(null),
			info: this.createInfoGroup(campaign),
			setup: new FormGroup({
				native: this.createNativeSetup(campaign),
				file: this.createFileSetup(),
				channels: this.createChannelsArray(campaign)
			}),
			settings: this.createSettingsGroup(campaign)
		});
	}

	private prepareFileCampaign(baseCampaign: Campaign<null>): Campaign<null> {
		Object.assign(baseCampaign.setup, { file: {} });
		return Object.assign(baseCampaign, structuredClone(this.form.value));
	}

	private prepareNativeCampaign(baseCampaign: Campaign<null>): Campaign<null> {
		Object.assign(baseCampaign.setup, { native: { contacts: [] } });

		const campaign = Object.assign(baseCampaign, structuredClone(this.form.value));

		if ( this.setupControls.native.controls.contacts.value.groups === null ) {
			campaign.setup.native!.contacts.groups = this.contactGroupsService.list.map(cg => cg.id);
		}
		if ( this.setupControls.native.controls.contacts.value.plain ) {
			campaign.setup.native!.contacts.plain = {
				code: this.contactsPlain.controls.code.value,
				text: this.contactsPlain.controls.text.value
					.replace(CONTACTS_SPLIT_PATTERN, ',')
			};
		}
		if ( campaign.setup.channels ) {
			campaign.setup.channels = campaign.setup.channels.sort(
				(ch1, _) => ch1.trafficType === TrafficType.SMS ? 1 : -1
			);
		}
		return campaign;
	}

	private initializeBaseCampaign(): Campaign<null> {
		return {
			id: null,
			info: {
				id: null,
				scheduled: '',
				status: CampaignStatus.SCHEDULED
			},
			setup: {
				channels: []
			},
			settings: {}
		};
	}

	private createInfoGroup(campaign?: Campaign): FormGroup<CampaignInfoControls> {
		const defaultName =
			`${this.translate.instant('campaigns.campaignName')} ${this.datePipe.transform(new Date(), ' yyyy-MM-dd HH:mm')}`;

		return new FormGroup<CampaignInfoControls>({
			id: new FormControl<Id>(null),
			name: new FormControl<string>(campaign?.info.name ?? defaultName, { nonNullable: true }),
			description: new FormControl<string>(campaign?.info.description ?? '', { nonNullable: true }),
			photo: new FormControl<string>('', { nonNullable: true }),
			scheduled: new FormControl<string>(
				new Date().toISOString(),
				{ nonNullable: true, validators: [Validators.required] }
			),
			status: new FormControl<CampaignStatus>(CampaignStatus.SCHEDULED, { nonNullable: true })
		});
	}

	private createNativeSetup(campaign?: Campaign): FormGroup<CampaignNativeControls> {
		return new FormGroup<CampaignNativeControls>({
			contacts: new FormGroup(
				{
					file: new FormControl<FileInfo | undefined>(
						{
							value: campaign?.setup.native?.contacts.file,
							disabled: !campaign?.setup.native?.contacts.file
						},
						{ nonNullable: true }
					),
					plain: new FormGroup({
						text: new FormControl<string>(
							{
								value: campaign?.setup.native?.contacts.plain?.text || '',
								disabled: !campaign?.setup.native?.contacts.plain?.text
							},
							{
								nonNullable: true,
								validators: [
									CPCustomValidators.recipientsLimitAndPattern(
										MAX_PHONE_NUMBER_AMOUNT,
										CONTACTS_SPLIT_PATTERN
									)
								]
							}
						),
						code: new FormControl<number | null>(
							{
								value: campaign?.setup.native?.contacts.plain?.code || null,
								disabled: !campaign?.setup.native?.contacts.plain?.code
							}
						)
					}),
					groups: new FormControl<Id<exist>[] | undefined>(
						{
							value: campaign?.setup.native?.contacts.groups || [],
							disabled: !campaign?.setup.native?.contacts.groups
								|| campaign?.setup.native?.contacts.groups.length === 0
						},
						{ nonNullable: true, validators: [CustomValidators.requiredArrayOrNull] }
					)
				},
				[Validators.required]
			)
		});
	}

	private createFileSetup(): FormGroup<{
		file: FormControl<FileInfo | null>
		columns: FormControl<FileParsedColumn[]>
	}> {
		return new FormGroup({
			file: new FormControl<FileInfo | null>(null, { validators: Validators.required }),
			columns: new FormControl<FileParsedColumn[]>([], { nonNullable: true })
		});
	}

	private createChannelsArray(campaign?: Campaign): FormArray<FormGroup<CampaignChannelSettingsControls>> {
		return new FormArray<FormGroup<CampaignChannelSettingsControls>>(
			campaign?.setup.channels?.map((chSetup) => this.createChannelGroup(chSetup)) || [],
			[Validators.required]
		);
	}

	private createChannelGroup(chSetup: CampaignChannelSettings): FormGroup<CampaignChannelSettingsControls> {
		const formGroup = new FormGroup<CampaignChannelSettingsControls>({
			sender: new FormControl<Id<exist> | null>(chSetup.sender),
			trafficType: new FormControl<TrafficType>(chSetup.trafficType, { nonNullable: true }),
			message: chSetup.message
				? new FormGroup<CampaignMessageControls>({
					text: new FormControl<string | undefined>(chSetup.message.text, { nonNullable: true }),
					templateID: new FormControl<Id<exist> | null>(chSetup.message.templateID || null)
				})
				: undefined
		});
		if ( chSetup.omni ) {
			formGroup.addControl('omni', this.createOmniSettingsGroup(chSetup));
		}
		return formGroup;
	}

	private createOmniSettingsGroup(chSetup: CampaignChannelSettings): FormGroup<CampaignOmniSettingsControls> {
		return new FormGroup<CampaignOmniSettingsControls>({
			fallbackType: new FormControl<DecisionMode>(
				chSetup.omni?.fallbackType || DecisionMode.BY_SUBMITTED, { nonNullable: true }
			),
			ttl: new FormControl<number>(chSetup.omni?.ttl || 30, { nonNullable: true }),
			viberSettings: chSetup.omni?.viberSettings
				? new FormGroup<CampaignViberSettingsControls>({
					buttonActionUrl: new FormControl<string>(
						chSetup.omni.viberSettings.buttonActionUrl || '', { nonNullable: true }
					),
					imageUrl: new FormControl<string>(chSetup.omni.viberSettings.imageUrl || '', { nonNullable: true }),
					buttonCaption: new FormControl<string>(
						chSetup.omni.viberSettings.buttonCaption || '', { nonNullable: true }
					),
					messagePurpose: new FormControl<MessagePurpose>(
						this.determineMessagePurpose(chSetup.omni.viberSettings), { nonNullable: true }
					)
				})
				: undefined
		});
	}

	private determineMessagePurpose(viberSettings: CampaignViberSettings): MessagePurpose {
		const { messagePurpose, buttonActionUrl, imageUrl, buttonCaption } = viberSettings;
		return (
			messagePurpose ||
			(buttonActionUrl || imageUrl || buttonCaption
				? MessagePurpose.PROMOTION
				: MessagePurpose.TRANSACTION)
		);
	}

	private createSettingsGroup(campaign?: Campaign): FormGroup<CampaignSettingsControls> {
		return new FormGroup<CampaignSettingsControls>({
			failureAlertThreshold: new FormControl<number>(
				{
					value: campaign?.settings.failureAlertThreshold || 100,
					disabled: !campaign?.settings.failureAlertThreshold
				},
				{ nonNullable: true }
			),
			emailReportAddresses: new FormControl<string>(
				{
					value: campaign?.settings.emailReportAddresses || '',
					disabled: !campaign?.settings.emailReportAddresses
				},
				{ nonNullable: true, validators: [Validators.required] } // todo add validators
			),
			messageSplitMode: new FormControl<MessageSplitMode>(
				campaign?.settings.messageSplitMode || MessageSplitMode.SPLIT, { nonNullable: true }
			),
			isFlashed: new FormControl<boolean>(campaign?.settings.isFlashed || false, { nonNullable: true })
		});
	}

	private disableNativeSetup(): void {
		this._form.controls.setup.controls.native.disable();
		this._form.controls.setup.controls.file.enable();
	}

	private disableFileSetup(): void {
		this._form.controls.setup.controls.file.disable();
		this._form.controls.setup.controls.native.enable();
	}
}
