<div [class]="type" [style.height.px]="imageHeight" class="img"></div>
@if (title) {
	<div class="title">{{ title | translate }}</div>
}
@if (subTitle) {
	<div class="sub-title">{{ subTitle | translate }}</div>
}
<div class="sub-title ng-content">
	<ng-content></ng-content>
</div>
<div class="in-row">
	@if (buttonText) {
		<button (click)="clicked()"
				[disabled]="disabled"
				alaris-button
				bType="primary" data-testid="emptyTableBtn"
				size="lg">
			<alaris-icon [name]="icon"></alaris-icon>
			{{ buttonText | translate }}
		</button>
	}
	@if (additionalButtonText) {
		<button (click)="additionsClicked()"
				[disabled]="disabled"
				alaris-button
				bType="outline" data-testid="emptyTableAdditionalBtn"
				size="lg">
			<alaris-icon [name]="additionalIcon"></alaris-icon>
			{{ additionalButtonText | translate }}
		</button>
	}
</div>
