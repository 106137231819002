import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { EmptyTableComponent } from '@helpers/empty-table/empty-table.component';
import { AlarisLinkComponent } from '@campaign-portal/components-library';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-empty-campaign-card',
	templateUrl: './empty-campaign-card.component.html',
	styleUrls: ['./empty-campaign-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AsyncPipe,
		EmptyTableComponent,
		AlarisLinkComponent,
		TranslateModule
	],
	standalone: true
})
export class EmptyCampaignCardComponent {
	@Input() loading$: Observable<boolean> = new BehaviorSubject<boolean>(true);
	@Input() hasSenders = false;
	@Input() hasSubscriptions = false;
	@Output() readonly senderRequest = new EventEmitter();
	@Output() readonly financeTransition = new EventEmitter();

	constructor(private router: Router) {
	}

	toPurchase(): void {
		this.router.navigate(['purchase'], { queryParams: { contactTabs: 'stopList' } });

	}
}
